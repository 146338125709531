import React from "react"
import _ from "lodash"

type Kind = "lines" | "squares" | "blank" | "dots"

type State = {
  rows: number
  columns: number

  widths: number[]
  heights: number[]

  //let's just be lazy and also ensures easy to restore/recreate?
  kinds: { [key: string]: Kind }

  showPage: boolean
}

const kindList: Kind[] = ["lines", "squares", "blank", "dots"]
const relativesSizes = _.range(1, 21)

class Stationery extends React.Component<void, State> {
  // @ts-ignore don't understand message about kinds stuff
  state = {
    rows: 2,
    columns: 2,
    widths: [1, 6],
    heights: [1, 8],
    showPage: false,
    kinds: { "0:0": "blank", "0:1": "lines", "1:0": "dots", "1:1": "squares" },
  }

  private handleKey = evt => {
    console.log(evt.keyCode)
    if (evt.keyCode == 27) {
      this.setState({ showPage: false })
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKey, false)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey, false)
  }

  render() {
    const { showPage } = this.state
    if (showPage) {
      return this.renderPage()
    } else {
      return this.renderForm()
    }
  }

  renderForm() {
    const { rows, columns, heights, widths, kinds } = this.state

    return (
      <div className="form-column">
        <h1>Custom Stationery Generator</h1>

        <p>
          Rows: {rows}{" "}
          <button
            onClick={() => {
              this.setState({
                rows: rows + 1,
                heights: heights.concat([1]),
              })
            }}
          >
            Add
          </button>{" "}
          {rows >= 2 && (
            <button
              onClick={() => {
                this.setState({
                  rows: rows - 1,
                  heights: heights.slice(0, heights.length - 1),
                })
              }}
            >
              Remove
            </button>
          )}
        </p>

        <p>
          Columns: {columns}{" "}
          <button
            onClick={() => {
              this.setState({
                columns: columns + 1,
                widths: widths.concat([1]),
              })
            }}
          >
            Add
          </button>{" "}
          {columns >= 2 && (
            <button
              onClick={() => {
                this.setState({
                  columns: columns - 1,
                  widths: widths.slice(0, widths.length - 1),
                })
              }}
            >
              Remove
            </button>
          )}
        </p>

        <div className="config-grid">
          <div>
            {_.range(this.state.columns).map(j => {
              return (
                <div>
                  Column {j}, w:&nbsp;
                  <select
                    value={widths[j]}
                    onChange={evt => {
                      const v = evt.target.value
                      widths[j] = parseInt(v)
                      this.setState({ widths })
                    }}
                  >
                    {relativesSizes.map(s => (
                      <option value={s}>{s}</option>
                    ))}
                  </select>
                </div>
              )
            })}
          </div>

          {_.range(this.state.rows).map(i => (
            <div key={i}>
              <div>
                Row {i}, h:&nbsp;
                <select
                  value={heights[i]}
                  onChange={evt => {
                    const v = evt.target.value
                    heights[i] = parseInt(v)
                    this.setState({ heights })
                  }}
                >
                  {relativesSizes.map(s => (
                    <option value={s}>{s}</option>
                  ))}
                </select>
              </div>
              {_.range(this.state.columns).map(j => {
                const lookupKey = `${i}:${j}`

                return (
                  <div key={j}>
                    <select
                      value={this.state.kinds[lookupKey] || "squares"}
                      onChange={evt => {
                        const v = evt.target.value
                        kinds[lookupKey] = v
                        this.setState({ kinds })
                      }}
                    >
                      {kindList.map((k, i) => (
                        <option value={k} key={i}>
                          {k}
                        </option>
                      ))}
                    </select>
                  </div>
                )
              })}
            </div>
          ))}
        </div>

        <p>
          Generates a printable page. NB may look a bit funny but if you try to
          print it should work out fairly nicely. Honestly is massive set of
          hacks, but good for learning about how to create something vaguely
          nice to print using HTML + CSS etc.
        </p>

        <p>
          Add or remove rows and columns, choose each cell type and choose the
          relative size of each row and column.{" "}
          <em>Press Escape to get back here</em>.
        </p>

        <button onClick={() => this.setState({ showPage: true })}>
          Generate!
        </button>
      </div>
    )
  }

  renderPage() {
    const { rows, columns, heights, widths, kinds } = this.state

    const rowTotal = heights.reduce((a, b) => a + b)
    const colTotal = widths.reduce((a, b) => a + b)

    //hardcoded A4-ish
    const pageHeight = 297
    const pageWidth = 210

    return (
      <div className="print-page">
        {_.range(rows).map(i => {
          return (
            <div style={{ flex: heights[i] }} key={i}>
              {_.range(columns).map(j => {
                const lookupKey = `${i}:${j}`
                const kind: Kind = kinds[lookupKey] || "squares"

                const h = (pageHeight * heights[i]) / rowTotal
                const w = (pageWidth * widths[j]) / colTotal

                return (
                  <div
                    style={{
                      flex: widths[j],
                      display: "flex",
                      alignItems: "stretch",
                      justifyContent: "stretch",
                      margin: 4,
                    }}
                    key={j}
                  >
                    {this.renderCell(kind, w, h)}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  renderCell = (kind: Kind, w: number, h: number) => {
    switch (kind) {
      case "squares":
        return (
          <svg viewBox={`0 0 ${w} ${h}`} style={{ flex: 1 }}>
            {_.range(0, h / 10 + 1).map(i => (
              <line
                x1={0}
                x2={10 * Math.floor(w / 10 + 1)}
                y1={i * 10}
                y2={i * 10}
                key={i}
                stroke="darkgrey"
                strokeWidth="0.01cm"
              />
            ))}

            {_.range(0, w / 10 + 1).map(i => (
              <line
                x1={i * 10}
                x2={i * 10}
                y1={0}
                y2={10 * Math.floor(h / 10 + 1)}
                key={i}
                stroke="darkgrey"
                strokeWidth="0.01cm"
              />
            ))}
          </svg>
        )
      case "lines":
        return (
          <svg viewBox={`0 0 ${w} ${h}`} style={{ flex: 1 }}>
            {_.range(0, h / 10 + 1).map(i => (
              <line
                x1={0}
                x2={w}
                y1={i * 10}
                y2={i * 10}
                key={i}
                stroke="#222"
                strokeWidth="0.01cm"
              />
            ))}
          </svg>
        )
      case "blank":
        return null
      case "dots":
        return (
          <svg viewBox={`0 0 ${w} ${h}`} style={{ flex: 1 }}>
            {_.range(0, h / 10 + 1).map(i => {
              return (
                <g>
                  {_.range(0, w / 10 + 1).map(j => (
                    <ellipse
                      rx="0.5"
                      ry="0.5"
                      cx={j * 10 + 0.5}
                      cy={i * 10 + 0.5}
                      key={`${i}${j}`}
                      fill="darkgrey"
                    />
                  ))}
                </g>
              )
            })}
          </svg>
        )
    }
  }
}

export default Stationery
